import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { SWITCH_LANGUAGE } from '../actions/types';

const Index = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const { navigator } = window;

    if (navigator.language.toLocaleLowerCase().includes(`en`)) {
      navigate(`/en`);
      dispatch({
        type: SWITCH_LANGUAGE,
        locale: `en`,
      });
    } else if (navigator.language.toLocaleLowerCase().includes(`fr`)) {
      navigate(`/fr`);
      dispatch({
        type: SWITCH_LANGUAGE,
        locale: `fr`,
      });
    } else {
      navigate(`/fr`);
      dispatch({
        type: SWITCH_LANGUAGE,
        locale: `fr`,
      });
    }
  });

  return <div />;
};

export default Index;
